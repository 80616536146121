.daily {
	background: url("/bg1.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #1a1350;
}

.entertainment {
	background: url("/entertainment-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #C44683;
}

.geography {
	background: url("/geography-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #3A98CB;
}

.sports {
	background: url("/sports-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #EC6C35;
}

.science {
	background: url("/science-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #8DC640;
}

.history {
	background: url("/history-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #FEE62A;
}

.arts {
	background: url("/arts-bg.jpg");
	background-position-x: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #9063B9;
}

.endless {
	background: url("/endless-bg.jpg");
	background-position-x: left;
	background-size: cover;
	background-attachment: fixed;
	background-color: #101735;
}

.trending {
	background: url("/trending-bg.jpg");
	background-position-x: left;
	background-size: cover;
	background-attachment: fixed;
	background-color: #c90a1a;
}

.staff_pick {
	background: url("/staff-picks-bg.jpg");
	background-position-x: left;
	background-size: cover;
	background-attachment: fixed;
	background-color: #F31260;
}